import React from "react";
import Head from "../../components/head.js";

import Layout from "../../components/layout";

const CyTwombly = () => {
  return (
    <Layout>
      <Head title="Cy Twombly" />
      <h3>Cy Twombly</h3>
      <p>1928 - 2011</p>
      <p>
        <a href="https://en.wikipedia.org/wiki/Cy_Twombly">Wikipedia page</a>
      </p>
      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1659547829/artnow/cy%20twombly/twombly1-min.jpg"
        alt="Cy Twombly abstract painting"
      />
      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1659547829/artnow/cy%20twombly/twombly2-min.jpg"
        alt="Cy Twombly abstract painting"
      />
    </Layout>
  );
};

export default CyTwombly;
